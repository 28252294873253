/** @jsxImportSource theme-ui */
import { PrimaryButton, Button } from '../uikit/buttons';
import Input from '../uikit/Input';
import Modal from '../uikit/Modal';
import { Field } from '../uikit/forms';
import { get, Omit } from 'lodash';
import React, { FormEvent } from 'react';
import { useToasts } from 'react-toast-notifications';

import { useMutation } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { DevDashboardError } from '../consts/DevDashboardError';
import { UPDATE_PASSWORD } from '../graphql/mutations/update-password';
import PasswordHoc, { PasswordHocInjectedProps } from '../hocs/PasswordHoc';
import { useAuth } from '../utils/authentication';

interface ChangePasswordProps extends PasswordHocInjectedProps {
  onRequestClose: () => any;
  isOpen: boolean;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({
  onRequestClose,
  isOpen,
  newPassword,
  onFieldBlur,
  updatePasswordField,
  passwordValidationError,
  isReadyToSubmitPasswords,
  resetNewPasswordState,
}) => {
  const { addToast } = useToasts();
  const { setAccessToken } = useAuth();
  const [updatePassword] = useMutation(UPDATE_PASSWORD);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    setLoading(true);
    try {
      const result = await updatePassword({
        variables: {
          ...newPassword,
        },
      });
      const accessToken = get(result, 'data.updatePassword.accessToken', null);
      if (!accessToken) {
        const errorMsg = get(
          result,
          'data.updatePassword.error.message',
          'An unknown error ocurred while attempting to change your password.'
        );
        const code = get(result, 'data.updatePassword.error.code', null);
        throw new DevDashboardError(errorMsg, code);
      }
      addToast('Password update completed!', {
        appearance: 'success',
        autoDismiss: true,
      });
      if (setAccessToken != null) {
        setAccessToken(accessToken);
      }
      onRequestClose();
      resetNewPasswordState();
    } catch (error) {
      const errorMsg = (error as Error).message.replace('GraphQL error: ', '');
      datadogLogs.logger.info('displayed error to user', { operation: 'updatePassword', errorMsg });
      addToast(errorMsg, { appearance: 'error', autoDismiss: true });
    } finally {
      setLoading(false);
    }
  };

  const onCloseClickHandler = () => {
    resetNewPasswordState();
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseClickHandler}
      appElement={document.getElementById('root') as HTMLElement}
      customStyles={{
        content: {
          maxHeight: '80vh',
        },
      }}
    >
      <div
        sx={{
          fontSize: '1.5rem',
          fontWeight: 500,
          marginBottom: '2rem',
        }}
      >
        Change Password
      </div>

      <form onSubmit={handleSubmit}>
        <Field
          label="Current Password"
          htmlFor="current-password"
          sx={{
            marginBottom: '2rem',
            '&:after': {
              content:
                passwordValidationError.current != null
                  ? `"${passwordValidationError.current}"`
                  : '""',
              marginTop: '4px',
              color: 'danger',
              fontSize: '0.75rem',
            },
          }}
        >
          <Input
            value={newPassword.currentPassword}
            onChange={(value: string) =>
              updatePasswordField({ ...newPassword, currentPassword: value })
            }
            id="current-password"
            type="password"
            required
            onBlur={() => onFieldBlur('current')}
          />
        </Field>
        <Field
          label="New Password"
          htmlFor="new-password"
          sx={{
            marginBottom: '2rem',
            '&:after': {
              content:
                passwordValidationError.password != null
                  ? `"${passwordValidationError.password}"`
                  : '""',
              marginTop: '4px',
              color: 'danger',
              fontSize: '0.75rem',
            },
          }}
        >
          <Input
            value={newPassword.password}
            onChange={(value: string) => updatePasswordField({ ...newPassword, password: value })}
            id="new-password"
            type="password"
            required
            onBlur={() => onFieldBlur('password')}
          />
        </Field>
        <Field
          label="Confirm New Password"
          htmlFor="confirm-new-password"
          sx={{
            marginBottom: '2rem',
            '&:after': {
              content:
                passwordValidationError.confirmation != null
                  ? `"${passwordValidationError.confirmation}"`
                  : '""',
              marginTop: '4px',
              color: 'danger',
              fontSize: '0.75rem',
            },
          }}
        >
          <Input
            value={newPassword.passwordConfirmation}
            onChange={(value: string) =>
              updatePasswordField({
                ...newPassword,
                passwordConfirmation: value,
              })
            }
            id="confirm-new-password"
            type="password"
            required
            onBlur={() => onFieldBlur('confirmation')}
          />
        </Field>

        <div
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button type="button" onClick={() => onCloseClickHandler()}>
            Cancel
          </Button>
          <PrimaryButton
            type="submit"
            sx={{ marginLeft: '1rem' }}
            disabled={loading || !isReadyToSubmitPasswords}
          >
            Save
          </PrimaryButton>
        </div>
      </form>
    </Modal>
  );
};

const ChangePasswordModal = (
  props: Omit<
    ChangePasswordProps,
    | 'newPassword'
    | 'onFieldBlur'
    | 'updatePasswordField'
    | 'passwordValidationError'
    | 'isReadyToSubmitPasswords'
    | 'resetNewPasswordState'
  >
) => {
  return <PasswordHoc initialProps={props} TargetComponent={ChangePassword} />;
};

export default ChangePasswordModal;
