import { Listbox } from '@headlessui/react';
import React from 'react';
import { Button } from '../button';
import { ActionListItem } from '../dropdown/actionList';

export type SelectItemProps = {
  label: string;
  value: string;
  unavailable?: boolean;
  onClick?: () => void;
};

export const Select = <IsMulti extends boolean = false>({
  label,
  items,
  selected,
  isDisabled,
  onSelected,
  className,
  isMulti,
  heading,
}: {
  label: string | React.ReactNode;
  isDisabled?: boolean;
  items: readonly SelectItemProps[];
  selected?: IsMulti extends true ? SelectItemProps[] : SelectItemProps;
  onSelected: (selected: IsMulti extends true ? SelectItemProps[] : SelectItemProps) => void;
  className?: string;
  isMulti?: IsMulti;
  heading?: React.ReactNode;
}) => {
  return (
    <div className="relative">
      <Listbox
        value={selected}
        onChange={(item) => {
          onSelected(item);
        }}
        multiple={isMulti}
      >
        {({ open }) => (
          <>
            <Listbox.Button as={React.Fragment}>
              <Button className={className} buttonType="outline" label={label} />
            </Listbox.Button>
            <Listbox.Options className="absolute z-20 flex flex-col bg-white rounded-3xl p-4 shadow-xl">
              {heading && heading}

              {items.map((item) => (
                <Listbox.Option
                  key={item.value}
                  value={item}
                  disabled={item.unavailable}
                  className={`w-60`}
                >
                  {({ active, selected, disabled }) => (
                    <ActionListItem
                      withCheckbox={!item.onClick}
                      onClick={(event) => {
                        if (item.onClick) {
                          event.preventDefault();
                          item.onClick();
                        }
                      }}
                      text={item.label}
                      disabled={disabled}
                      active={active}
                      selected={selected}
                    />
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </div>
  );
};
