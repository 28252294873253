import React from 'react';
import { Footer } from '../../AuthenticatedApp';
import { Button } from '../../designs-v2/components/button';
import { Icon } from '../../designs-v2/primitives/icons';
import { useLocation, useHistory } from 'react-router-dom';
import WorkspaceSwitcher from '../WorkspaceSwitcher';
import { UserMenu } from './UserMenu';
import { useIsAdminOrOwner, useCurrentWorkspaceRole } from '../../utils/identity';
import { useGate } from 'statsig-react';
import { useCookie } from '../../hooks/use-cookies';
import { MemberRole } from '../../consts/models';

export const DeveloperPageValidRoles: MemberRole[] = ['administrator', 'developer'];

export function useDevDashboardV2FeatureFlag(): boolean {
  const showAnalyticsGate = useGate('ff-enable-dashboard-analytics');
  return showAnalyticsGate?.value;
  // return false;
}

const HeaderButton: React.FC<{ route: string; label: string }> = ({ label, route }) => {
  const location = useLocation();
  const history = useHistory();

  return (
    <Button
      label={label}
      buttonType="text_tonal"
      onClick={() => history.push(route)}
      className={`${location.pathname === route ? 'font-bold bg-bg-muted' : ''}`}
    />
  );
};

const PageHeader: React.FC = () => {
  const history = useHistory();
  const isAdminOrOwner = useIsAdminOrOwner();
  const role = useCurrentWorkspaceRole();

  return (
    <div className="w-full flex justify-between border-b-border-subtle border-b px-8 py">
      <div className="flex gap-2 whitespace-normal">
        <button onClick={() => history.push('/')} className="py-4 flex items-center">
          <Icon type="pinwheel" className="pr-4 border-r border-r-border-subtle" />
        </button>
        <HeaderButton route="/analytics" label="Activity" />
        {DeveloperPageValidRoles.some((r) => r === role) && (
          <>
            <HeaderButton route="/test-console" label="Console" />
            <HeaderButton route="/developers" label="Developers" />
          </>
        )}
        {isAdminOrOwner && <HeaderButton route="/team" label="Team" />}
      </div>
      <div className="flex flex-row gap-2 my-2">
        <WorkspaceSwitcher />
        <UserMenu />
      </div>
    </div>
  );
};

const Banner = () => {
  const [announcementsUnread, setAnnouncementsUnread] = useCookie<'true' | 'false'>(
    'announcementsUnread',
    'true'
  );

  if (!announcementsUnread || announcementsUnread === 'false') {
    return null;
  }

  return (
    <div
      className="flex flex-row justify-between py-3 px-4"
      style={{
        background:
          'radial-gradient(0% 105.41% at 100% 50%, #F9F9FB 4.12%, #EBF0FF 33.79%, #E5E0FB 66.94%, #F3E1F2 86.21%)',
      }}
    >
      <div>{/* Empty div for justify-between to work as expected */}</div>

      <div className="flex flex-row gap-4 items-center">
        <p className="text-preset-3">See what new features we’ve added</p>
        <button
          onClick={() => (window.location.href = '/analytics')}
          className="flex flex-row bg-content-default text-content-on-inverse text-preset-2 py-1.5 px-3.5 rounded-full items-center gap-1"
        >
          Explore Now
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              fill="white"
              fillRule="evenodd"
              d="M11.293 4.293a1 1 0 0 1 1.414 0l7 7a1 1 0 0 1 0 1.414l-7 7a1 1 0 0 1-1.414-1.414L16.586 13H5a1 1 0 1 1 0-2h11.586l-5.293-5.293a1 1 0 0 1 0-1.414Z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      <button aria-label="close announcements" onClick={() => setAnnouncementsUnread('false')}>
        <Icon type="x-close" />
      </button>
    </div>
  );
};

export const NewLayout: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <div className="flex flex-col w-full flex-grow bg-white">
    <Banner />
    <PageHeader />
    <div className="flex flex-row justify-center flex-1">{children}</div>
  </div>
);
