/** @jsxImportSource theme-ui */
import React from 'react';
import QRCode from 'react-qr-code';
import { ListRow } from '../../components/layouts/ListRow';
import { SmartBranchUrl } from './SmartBranchUrl';

type SmartBranchRowProps = {
  name: string;
  url: string;
  createdAt: Date;
};

export const SmartBranchRow: React.FC<SmartBranchRowProps> = ({ name, url, createdAt }) => {
  return (
    <ListRow
      icon="statusActive"
      title={name}
      subtitle={`Created ${createdAt.toLocaleDateString()}`}
      actionButtons={
        <>
          <SmartBranchUrl url={url} />
          <div sx={{ height: 'auto', marginLeft: '1rem', maxWidth: 50, width: '100%' }}>
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={url}
              viewBox={`0 0 256 256`}
            />
          </div>
        </>
      }
    />
  );
};
