/** @jsxImportSource theme-ui */
import { Button } from '../../uikit/buttons';
import { useCallback } from 'react';
import { InputText } from '../Form/InputText';
import { InputTextArea } from '../Form/InputTextArea';
import CreateSmartBranchUrlButton from './CreateSmartBranchUrlButton';
import { TSmartBranchForm } from './SmartBranchForm';

export type SmartBranchFormKey = keyof TSmartBranchForm;
export type OnUrlCreation = (url: string) => void;

export default function <F>({
  form,
  setForm,
  formInitialState,
  submitFormFn,
  onFormSuccess,
}: {
  form: F;
  setForm: (state: F) => void;
  formInitialState: F;
  submitFormFn: (_form: F) => Promise<any>;
  onFormSuccess: OnUrlCreation;
}) {
  const setFormFields = useCallback(
    (fields: Partial<Record<SmartBranchFormKey, any>>) => {
      setForm({ ...form, ...fields });
    },
    [form, setForm]
  );

  return (
    <div>
      <div sx={{ width: '100%', display: 'flex' }}>
        <div sx={{ fontSize: 2, fontWeight: 500, marginRight: 'auto' }}>Smart Branch</div>
        <Button
          data-testid="clearFormButton"
          onClick={() => setForm(formInitialState)}
          size="small"
        >
          <div>Reset all</div>
        </Button>
      </div>
      <div style={{ margin: '2.5rem 0' }}>
        <InputText
          name="name"
          label="Nickname for Smart Branch URL"
          type="text"
          value={form['name']}
          onChange={(val: string) => {
            setFormFields({ ['name']: val });
          }}
          required
        />
        <InputText
          name="email"
          label="Email"
          type="text"
          value={form['email']}
          onChange={(val: string) => {
            setFormFields({ ['email']: val });
          }}
        />
        <InputTextArea
          name="tags"
          label="Tags"
          value={form['tags']}
          onChange={(val: string) => {
            setFormFields({ ['tags']: val });
          }}
        />
      </div>
      <CreateSmartBranchUrlButton<F>
        form={form}
        disabled={false}
        submitFormFn={submitFormFn}
        onFormSuccess={onFormSuccess}
      />
    </div>
  );
}
