/** @jsxImportSource theme-ui */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { StatsigProvider, useGate } from 'statsig-react';
import APIKeys from './pages/APIKeys';

import WorkspaceSwitcher from './components/WorkspaceSwitcher';
import EmployerMatching from './pages/EmployerMatching';
import SmartBranchPage from './pages/smart_branch/SmartBranch';
import TeamMembers from './pages/teams/TeamMembers';
import TestConsolePage from './pages/TestConsolePage';
import Header from './uikit/Header';

import {
  DeveloperPageValidRoles,
  NewLayout,
  useDevDashboardV2FeatureFlag,
} from './components/layouts/layouts-v2';
import { UserMenu } from './components/layouts/UserMenu';
import { SidebarMenu } from './components/SidebarMenu';
import { MemberRole, User } from './consts/models';
import { AnalyticsPage } from './pages/analytics/index';
import { CompanyConnectPage } from './pages/company_connect/CompanyConnect';
import { useCurrentWorkspace, useCurrentWorkspaceRole, useIsAdminOrOwner } from './utils/identity';
import { useSession } from './utils/session';
import { HomePage } from './pages/home/home-page';
import { UserListPage } from './pages/tools/user-list';
import { ToolsPage } from './pages/tools/index';
import { SegmentsPage } from './pages/tools/segments';
import JoinWorkspaceAuth0 from './pages/JoinWorkspaceAuth0';
import { useIsAuth0Mode } from './utils/auth0';
import CompleteProfile from './pages/CompleteProfile';
import { useHistory } from 'react-router-dom';
import { Loader } from '@pinwheel/origami';

const LayoutWrapper: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <NewLayout>
      <div className="min-w-[70%] my-6 flex flex-col gap-4 justify-between">
        {children}
        <Footer />
      </div>
    </NewLayout>
  );
};

const wrapLayout = (Component: React.FC) => {
  return () => (
    <LayoutWrapper>
      <Component />
    </LayoutWrapper>
  );
};

const WorkspaceOwnerRoutes = [
  <Route exact path="/team" component={wrapLayout(TeamMembers)} key="route__team" />,
];

const Routes = () => {
  const isAdminOrOwner = useIsAdminOrOwner();
  const session = useSession();

  const userRole = useCurrentWorkspaceRole();

  const isAuth0Mode = useIsAuth0Mode();

  if (session.loading || !session.data) {
    return null;
  }

  const workspaceOwnerRoutes = isAdminOrOwner ? WorkspaceOwnerRoutes : [];

  return (
    <Switch>
      {workspaceOwnerRoutes}

      <Route exact path="/employer-matching" component={wrapLayout(EmployerMatching)} />
      <Route path="/smart-branch" component={wrapLayout(SmartBranchPage)} />
      <Route
        path="/analytics"
        component={() => (
          <NewLayout>
            <AnalyticsPage />
          </NewLayout>
        )}
      />

      {DeveloperPageValidRoles.some((role) => role === userRole) && (
        <Route
          exact
          path="/test-console"
          component={wrapLayout(TestConsolePage)}
          key="route__test-console"
        />
      )}

      {DeveloperPageValidRoles.some((role) => role === userRole) && (
        <Route path="/developers" component={wrapLayout(APIKeys)} />
      )}
      <Route
        exact
        path="/tools"
        component={() => (
          <NewLayout>
            <ToolsPage />
          </NewLayout>
        )}
      />
      <Route
        exact
        path="/tools/segments"
        component={() => (
          <NewLayout>
            <SegmentsPage />
          </NewLayout>
        )}
      />
      <Route
        exact
        path={'/tools/users'}
        render={() => (
          <NewLayout>
            <UserListPage />
          </NewLayout>
        )}
      />

      {isAuth0Mode && <Route path="/join/:token" component={JoinWorkspaceAuth0} />}

      {isAuth0Mode && <Route path="/complete-profile" component={CompleteProfile} />}

      <Route
        exact
        path="/"
        component={() => (
          <NewLayout>
            <HomePage />
          </NewLayout>
        )}
      />
      <Redirect to="/" />
    </Switch>
  );
};

export const Footer = () => {
  return (
    <div
      sx={{
        marginTop: '1.5rem',
        fontSize: '16px',
        lineHeight: '24px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <span>
        Check out our{' '}
        <a
          sx={{ textDecoration: 'none', color: 'primary' }}
          href="https://docs.getpinwheel.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          docs
        </a>
      </span>
      <span>
        Need help?{' '}
        <a sx={{ textDecoration: 'none', color: 'primary' }} href="mailto:sales@pinwheelapi.com">
          Contact Us
        </a>
      </span>
    </div>
  );
};

const LegacyLayout: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <>
      <Header left={<WorkspaceSwitcher />} right={<UserMenu />} />

      <div className="flex mb-5 flex-shrink">
        <SidebarMenu />
        <div className="flex-grow ml-3 mr-12 max-w-[70%]">
          {children}
          <Footer />
        </div>
      </div>
    </>
  );
};

const AuthenticatedApp: React.FC = () => {
  const currentWorkspace = useCurrentWorkspace();

  const session = useSession();
  const history = useHistory();

  const user = session.data as User;

  // HACK/TODO: this should ideally be done via the Auth0 actions flow
  React.useEffect(() => {
    if (!user) return;
    if (!user.firstName) {
      history.push('/complete-profile');
    }
  }, [user]);

  if (session.loading || !session.data) {
    return (
      <div className="flex items-center justify-center min-h-screen h-screen">
        <Loader type="logo" />
      </div>
    );
  }

  // statsig setup
  // Use local mode if we do not have a valid client key.
  const localMode = !process.env.REACT_APP_STATSIG_CLIENT_KEY;
  // Even in local mode, Statsig wants a key, hence client-dummy.
  const keyToUse = process.env.REACT_APP_STATSIG_CLIENT_KEY
    ? process.env.REACT_APP_STATSIG_CLIENT_KEY
    : 'client-dummy';

  return (
    <StatsigProvider
      sdkKey={keyToUse}
      user={{
        userID: user.id,
        customIDs: {
          workspace_name: currentWorkspace?.name || '',
        },
        custom: { PINWHEEL_ENV: process.env.REACT_APP_PINWHEEL_ENVIRONMENT },
      }}
      waitForInitialization={true}
      options={{
        localMode,
        environment: {
          tier: ['staging', 'production'].includes(process.env.REACT_APP_PINWHEEL_ENVIRONMENT || '')
            ? process.env.REACT_APP_PINWHEEL_ENVIRONMENT
            : 'development',
        },
      }}
    >
      <Routes />
    </StatsigProvider>
  );
};

export default AuthenticatedApp;
