import { DemandPartnerCustomer, Employer } from '../../consts/models';
import { EmptyValue, InputSelectOption } from '../Form/utils';
import { ModeKey } from './form-helpers';
export type { Employer };

const READ_JOBS = [
  'employment',
  'identity',
  'income',
  'paystubs',
  'shifts',
  'direct_deposit_allocations',
  'tax_forms',
  'payout_cards',
] as const;
export const WRITE_JOBS = ['direct_deposit_switch', 'card_switch'] as const;
export const ALL_JOBS = [...WRITE_JOBS, ...READ_JOBS];
export type ReadOrWriteJob = typeof READ_JOBS[number] | typeof WRITE_JOBS[number];

export const PLATFORM_TYPES = ['payroll', 'time_and_attendance'];
export type PlatformType = typeof PLATFORM_TYPES[number];

export const FEATURE_LAUNCH_TYPES = ['direct', 'fallback', 'disabled'];
export type FeatureLaunchType = typeof FEATURE_LAUNCH_TYPES[number];

export const SUPPORTED_LANGUAGES: Language[] = ['en', 'es'];
export type Language = 'en' | 'es';

export type TLinkTokenForm = {
  mode?: InputSelectOption<ModeKey>;
  requiredJobs?: InputSelectOption<ReadOrWriteJob>[];
  orgName: string;
  demandPartnerCustomer?: { value?: DemandPartnerCustomer; label: string };
  amount: string;
  selectedEmployer?: { value?: Employer; label: string };
  skipIntroScreen: boolean;
  disableDirectDepositSplitting: boolean;
  documentUploads?: InputSelectOption<FeatureLaunchType>;
  accountId?: string;
  endUserId?: string;
  platformType?: InputSelectOption<PlatformType | EmptyValue>;
  redirectUrl?: string;
  language?: { value: Language; label: string };
  accounts?: any;
  platformMatching?: any;
  card?: any;
  emptyBox: string;
  enableCardSwitch: boolean;
  depositForms?: InputSelectOption<FeatureLaunchType>;
};

export type LinkTokenFormKey = keyof TLinkTokenForm;
