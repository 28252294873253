import { useMutation } from '@apollo/client';
import React from 'react';
import ChangePasswordModal from '../ChangePasswordModal';
import Dropdown from '../../uikit/Dropdown';
import {
  Body as DropdownBody,
  Item as DropdownItem,
  Toggle as DropdownToggle,
} from '../../uikit/Dropdown';
import UserAvatar from '../../uikit/UserAvatar';
import { useAuth } from '../../utils/authentication';
import { User } from '../../consts/models';
import { SIGNOUT } from '../../graphql/mutations/signout';
import { useSession } from '../../utils/session';
import { useIsAuth0Mode } from '../../utils/auth0';
import { useAuth0 } from '@auth0/auth0-react';

export const UserMenu: React.FC<{}> = () => {
  const { setAccessToken } = useAuth();
  const session = useSession();
  const [isEditProfileModalOpen, setEditProfileModalOpen] = React.useState(false);
  const [signout] = useMutation(SIGNOUT);

  const isAuth0Mode = useIsAuth0Mode();
  const { logout } = useAuth0();

  if (session.loading || !session.data) {
    return null;
  }

  const onSignout = async () => {
    if (isAuth0Mode) {
      void logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    } else {
      await signout();
      setAccessToken!(null);
    }
  };

  const user = session.data as User;
  return (
    <>
      <Dropdown>
        <DropdownToggle>
          <UserAvatar
            initials={`${
              user.firstName && user.firstName.length > 0 ? user.firstName.slice(0, 1) : ''
            }${user.lastName && user.lastName.length > 0 ? user.lastName.slice(0, 1) : ''}`}
            name={`${user.firstName} ${user.lastName}`}
          />
        </DropdownToggle>

        <DropdownBody customSx={{ left: 'initial', right: 0 }}>
          {
            // TODO: https://pinwheel.atlassian.net/browse/GROW-1423
            !isAuth0Mode && (
              <DropdownItem onClick={() => setEditProfileModalOpen(true)}>
                Change Password
              </DropdownItem>
            )
          }
          <DropdownItem onClick={onSignout}>Log out</DropdownItem>
        </DropdownBody>
      </Dropdown>

      <ChangePasswordModal
        isOpen={isEditProfileModalOpen}
        onRequestClose={() => setEditProfileModalOpen(false)}
      />
    </>
  );
};
